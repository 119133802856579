<template>
  <div ref="container" class="immunizations-editor">
    <el-dialog v-model="dialogVisibility" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
        <template v-if="isBatch">
          <el-form-item v-if="hasFacilityTypeFilter()" label="Facility Type" prop="selectedFacType" :rules="[{ required: true, message: 'Facility type is required' }]">
            <el-select v-model="formModel.selectedFacType">
              <el-option v-for="item in ['RET', 'LTC']" :key="item" :label="item" :value="item"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Facility" prop="selectedFacId" :rules="[{ required: true, message: 'Facility is required' }]">
            <el-select v-model="formModel.selectedFacId" :disabled="hasFacilityTypeFilter() && !formModel.selectedFacType">
              <el-option v-for="item in availableSites" :key="item.id" :label="item.displayText" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Unit" prop="selectedUnitId" v-if="isStaff === false">
            <el-select v-model="formModel.selectedUnitId" :disabled="!formModel.selectedFacId">
              <el-option :key="'All'" :value="'All'">All</el-option>
              <el-option v-for="item in availableUnits" :key="item.id" :label="item.displayText" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Individuals" class="individuals" prop="selectedIndividuals" :rules="[{ required: isStaff ? !!formModel.selectedFacId : !!formModel.selectedUnitId, validator: validateIndividuals }]">
            <div class="select-offset">
              <el-select ref="individualSelect" v-model="formModel.selectedIndividuals" :multiple="true" :clearable="true" @change="clearSearch" :filterable="true" :disabled="disableIndividualPicker()">
                <el-option v-for="item in availableIndividuals" :key="item.id" :label="`${item.firstName} ${item.lastName} (${item.id})`" :value="item.id"> </el-option>
              </el-select>
            </div>
            <div>
              <el-button @click="selectAllIndividuals" size="mini">Select All Individuals</el-button>
              <el-button @click="clearAllIndividuals" size="mini">Clear</el-button>
            </div>
          </el-form-item>
        </template>
        <!-- Immunization Type -->
        <el-form-item label="Immunization Type" prop="description">
          <el-select v-model="formModel.description" filterable>
            <el-option v-for="item in immunizationTypes" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Immunization Administration Date -->
        <el-form-item label="Administration Date" prop="date">
          <el-date-picker v-model="formModel.date" type="date" :format="$configStore.datePickerDateFormat()" :pickerOptions="administrationPickerOptions" placeholder="Select date"> </el-date-picker>
        </el-form-item>
        <!-- Immunization Expiry Date -->
        <el-form-item label="Expiry Date" prop="expiry">
          <el-date-picker v-model="formModel.expiry" type="date" :format="$configStore.datePickerDateFormat()" :pickerOptions="expiryPickerOptions" placeholder="Select date"> </el-date-picker>
        </el-form-item>
        <!-- Declined -->
        <el-form-item label="Declined?" prop="declined">
          <el-select v-model="formModel.declined">
            <el-option label="Yes" :value="true"></el-option>
            <el-option label="No" :value="false"></el-option>
          </el-select>
        </el-form-item>
        <!-- Declined Reason -->
        <el-form-item v-if="formModel.declined === true" label="Reason" prop="declinedReason">
          <el-select v-model="formModel.declinedReason" filterable>
            <el-option v-for="item in declinedReasons" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Immunization location -->
        <el-form-item v-if="formModel.declined === false" label="Location" prop="location">
          <el-select v-model="formModel.location" filterable>
            <el-option v-for="item in locations" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Vaccine Type -->
        <el-form-item v-if="formModel.declined === false" label="Vaccine Type" prop="vaccineType">
          <el-select v-model="formModel.vaccineType" filterable>
            <el-option v-for="item in vaccineTypes" :key="item" :label="item" :value="item"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Lot Number -->
        <el-form-item v-if="formModel.declined === false" label="Lot Number" prop="lotNumber">
          <el-input placeholder="Lot Number" v-model="formModel.lotNumber"></el-input>
        </el-form-item>
        <!-- Notes -->
        <el-form-item label="Notes" prop="note">
          <el-input type="textarea" :rows="3" :maxlength="notesMaxLength" placeholder="Notes" v-model="formModel.note"></el-input>
          <character-count :limit="notesMaxLength" :count="formModel.note.length" />
        </el-form-item>
        <div class="dialog-footer">
          <el-row>
            <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
            <el-button type="primary" @click="commitForm(false)" :disabled="dialogBusy">{{ submitButton }}</el-button>
          </el-row>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const validateBoolean = (rule, value, callback) => {
  if (typeof value !== "boolean") {
    callback("This field cannot be empty");
  }
  callback();
};
const validateArrayNotEmpty = (rule, value, callback) => {
  if (!value.length) {
    callback("This field cannot be empty");
  }
  callback();
};
import CharacterCount from "../Shared/CharacterCount.vue";
import { Loading } from "element-ui";
import auth from "../../auth";
import util from "../../util";
import moment from "moment";

export default {
  name: "immunization-editor",
  props: ["title", "immunization", "clientId", "isBatch", "isStaff"],
  components: {
    "character-count": CharacterCount,
  },
  data() {
    return {
      isEdit: false,
      submitButton: "",
      notesMaxLength: 250,
      dialogVisibility: true,
      dialogBusy: true,
      administrationPickerOptions: {
        disabledDate: (input) => {
          // disable dates after current date and after expiry date
          const currentDate = moment().startOf("day");
          const datepickerDate = moment(input).startOf("day");
          return datepickerDate.isAfter(currentDate) || (this.formModel.expiry && datepickerDate.isAfter(moment(this.formModel.expiry).startOf("day")));
        },
      },
      expiryPickerOptions: {
        disabledDate: (input) => {
          // disable dates before administration date
          const currentDate = moment().startOf("day");
          const datepickerDate = moment(input).startOf("day");
          return datepickerDate.isBefore(this.formModel.date ? moment(this.formModel.date).startOf("day") : currentDate);
        },
      },
      facilities: [],
      immunizationConfig: {},
      immunizationTypes: [],
      vaccineTypes: [],
      declinedReasons: [],
      allIndividuals: [],
      availableIndividuals: [],
      locations: [],
      formRules: {
        description: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        date: [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }],
        declined: [{ required: true, validator: validateBoolean, message: "This field  is required", trigger: "blur" }],
      },
      formModel: {
        description: null,
        date: null,
        expiry: null,
        declined: null,
        declinedReason: null,
        location: null,
        vaccineType: null,
        lotNumber: null,
        note: "",
        selectedFacType: null,
        selectedFacId: null,
        selectedUnitId: null,
        selectedIndividuals: [],
      },
    };
  },
  watch: {
    dialogVisibility(val) {
      if (val === false) {
        this.cancelForm();
      }
    },
    "formModel.selectedFacType": function () {
      this.formModel.selectedFacId = null;
      this.formModel.selectedUnitId = null;
      this.formModel.selectedIndividuals = [];
    },
    "formModel.selectedFacId": function () {
      this.formModel.selectedIndividuals = [];
      this.getIndividualsForSelectedFac();
    },
    "formModel.selectedUnitId": function (value) {
      this.formModel.selectedIndividuals = [];
      if (value === "All") {
        this.availableIndividuals = this.allIndividuals;
      } else {
        this.availableIndividuals = this.allIndividuals.filter((person) => person.unitId === value);
      }
    },
    "formModel.description"(val) {
      if (this.immunizationConfig) {
        this.vaccineTypes = this.immunizationConfig[val] ? this.immunizationConfig[val] : ["Unknown", "Other", "Not Applicable"];
      } else {
        // this is a fallback if imm config is not setup in DB
        if (!this.isRevera) {
          return;
        }
        if (value === "COVID-19") {
          this.locations = ["Unknown", "Left Deltoid", "Right Deltoid"];
          this.vaccineTypes = [
            "Unknown",
            "AstraZeneca COVID-19 Dose 1",
            "AstraZeneca COVID-19 Dose 2",
            "AstraZeneca COVID-19 Dose 3",
            "COMIRNATY Bivalent Original / Omicron BA.4/BA.5",
            "COMIRNATY Bivalent Booster #2",
            "Covifenz COVID-19 Dose 1",
            "Covifenz COVID-19 Dose 2",
            "Janssen COVID-19",
            "Moderna COVID-19 Dose 1",
            "Moderna COVID-19 Dose 2",
            "Moderna COVID-19 Dose 3",
            "Moderna COVID-19 Dose 4",
            "Moderna COVID-19 Dose 5",
            "Pfizer COVID-19 Dose 1",
            "Pfizer COVID-19 Dose 2",
            "Pfizer COVID-19 Dose 3",
            "Pfizer COVID-19 Dose 4",
            "Pfizer COVID-19 Dose 5",
            "Nuvaxovid COVID-19 Dose 1",
            "Nuvaxovid COVID-19 Dose 2",
            "SPIKEVAX Bivalent Vaccine",
            "SPIKEVAX Bivalent Original / Omicron BA.4/5",
            "SPIKEVAX Bivalent Booster #2",
          ];
        } else if (value === "Influenza") {
          this.locations = ["Unknown", "Left Deltoid", "Right Deltoid"];
          this.vaccineTypes = ["Unknown", "Afluria Tetra", "Fluad", "Flucelvax QUAD", "Flulaval Tetra", "Fluzone High-Dose QUAD", "Fluzone Quad", "Supemtek"];
        } else {
          this.locations = util.sortWithFixedLeadingElements(this.config.ImmunizationLoc || [], "Unknown");
          this.vaccineTypes = ["Not Available", "Other", "Unknown", "Not Applicable"];
        }
      }
    },
    "formModel.declined"(val) {
      if (val === true) {
        // when declined, declinedReason is required & vaccineType/location must be null
        this.formRules.declinedReason = [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }];
        delete this.formRules.vaccineType;
        delete this.formRules.location;
        this.formModel.vaccineType = null;
        this.formModel.location = null;
      } else if (val === false) {
        // when not declined, vaccineType/location are required & declinedReason must be null
        this.formRules.vaccineType = [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }];
        this.formRules.location = [{ required: true, validator: validateNotEmpty, message: "This field  is required", trigger: "blur" }];
        delete this.formRules.declinedReason;
        this.formModel.declinedReason = null;
      } else {
        // reset declined conditions when empty
        delete this.formRules.declinedReason;
        delete this.formRules.vaccineType;
        delete this.formRules.location;
        this.formModel.declinedReason = null;
        this.formModel.vaccineType = null;
        this.formModel.location = null;
      }
    },
  },
  computed: {
    availableSites() {
      const type = this.formModel.selectedFacType;
      if (type) {
        return (this.sites || []).filter((fac) => (fac.unitType === null && type === "LTC") || fac.unitType === type);
      } else {
        return this.sites;
      }
    },
    availableUnits() {
      let units = [];
      if (this.formModel.selectedFacId) {
        const fac = this.availableSites.filter((fac) => fac.id === this.formModel.selectedFacId)[0];
        if (fac) {
          units = (fac.units || []).filter((unit) => unit.code !== "PLACE_HOLDER");
        }
      }
      return units;
    },
  },
  methods: {
    async getImmunizationConfig() {
      try {
        const result = await this.$http.get(`${window.CONFIG.immunization_api}/config`);
        return result.data;
      } catch (err) {
        return null; // see created() for temporary workaround to use the old config if imm config db not setup
      }
    },
    setupForm() {
      // setup form either for editing an existing immunization or creating a immunization record/batch
      if (this.isEdit) {
        this.submitButton = "Edit";
        this.formModel.description = this.immunization.description;
        this.formModel.date = this.immunization.date ? moment.utc(this.immunization.date).format(this.$configStore.dateFormat()) : null;
        this.formModel.expiry = this.immunization.expiry ? moment.utc(this.immunization.expiry).format(this.$configStore.dateFormat()) : null;
        this.formModel.declined = this.immunization.declined;
        this.formModel.declinedReason = this.immunization.declinedReason;
        this.formModel.location = this.immunization.location;
        this.formModel.vaccineType = this.immunization.vaccineType;
        this.formModel.lotNumber = this.immunization.lotNumber;
        this.formModel.note = this.immunization.note || "";
      } else {
        this.submitButton = "Add";
      }
    },
    cancelForm() {
      this.$emit("immunization-editor-close");
    },
    async commitForm() {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogBusy = true;
            // setup request body
            const raw = { ...this.formModel };
            const immunization = {
              description: raw.description,
              date: raw.date,
              expiry: raw.expiry || null,
              declined: raw.declined,
              declinedReason: raw.declinedReason,
              location: raw.location,
              vaccineType: raw.vaccineType,
              lotNumber: raw.lotNumber,
              note: raw.note,
            };
            let body;
            if (this.isBatch) {
              const clientIds = raw.selectedIndividuals;
              body = {
                immunization,
                clientIds,
              };
            } else {
              immunization.clientId = this.clientId;
              body = immunization;
            }
            // create or update immunization
            let result;
            if (this.isEdit) {
              result = await this.$http.patch(`${window.CONFIG.immunization_api}/${this.immunization.id}`, body);
            } else {
              result = await this.$http.post(`${window.CONFIG.immunization_api}/${this.isBatch ? "batch" : ""}`, body);
            }
            console.log({ result });
            this.dialogBusy = false;
            this.$emit("immunization-editor-save");
          } catch (error) {
            this.$emit("immunization-editor-error");
          }
        } else {
          return false;
        }
      });
    },
    hasFacilityTypeFilter() {
      return auth.userInfo().roles.indexOf("ROLE_LTC") >= 0 && auth.userInfo().roles.indexOf("ROLE_RET") >= 0;
    },
    validateIndividuals(rule, value, callback) {
      if (this.disableIndividualAll() && this.disableIndividualPicker()) {
        callback();
      } else if (this.formModel.selectedIndividuals.length === 0) {
        callback(new Error("Select all or some individuals"));
      } else {
        callback();
      }
    },
    disableIndividualAll() {
      return this.isStaff ? !this.formModel.selectedFacId : !this.formModel.selectedUnitId;
    },
    disableIndividualPicker() {
      return this.isStaff ? !this.formModel.selectedFacId : !this.formModel.selectedUnitId;
    },
    async getIndividualsForSelectedFac() {
      if (this.formModel.selectedFacId) {
        const loadingInstance = Loading.service({ fullscreen: true });
        const clientType = `${this.isStaff ? "Staff" : "Client"}`;
        const response = await this.$http.get(`${window.CONFIG.api}/clients/list/unit/0/page/0?clientType=${clientType}&size=10000&sort=firstName&facIds=${this.formModel.selectedFacId}`);
        loadingInstance.close();
        const isActive = (i) => (this.isStaff ? i.status === "C" : i.status !== "T" && i.status !== "D");
        this.allIndividuals = response.body.content.filter(isActive);
        this.availableIndividuals = this.allIndividuals;
      }
    },
    selectAllIndividuals() {
      this.formModel.selectedIndividuals = this.availableIndividuals.map((i) => i.id);
    },
    clearAllIndividuals() {
      this.formModel.selectedIndividuals = [];
    },
    clearSearch() {
      this.$refs.individualSelect.query = "";
    },
    relocate() {
      if (this.$refs.container) {
        document.body.append(this.$refs.container);
      }
    },
    validateExpiryDate(rule, value, callback) {
      if (this.formModel.date && value && moment(value).isBefore(moment(this.formModel.date))) {
        callback(new Error("Expiry date cannot be before the administration date"));
      } else {
        callback();
      }
    },
  },
  async created() {
    this.sites = this.$configStore.data.sites || [];
    this.units = (this.$configStore.data.units || []).filter((unit) => unit.code !== "PLACE_HOLDER");
    this.isEdit = !!this.immunization.id;
    this.isRevera = auth.userInfo().org === "revera" || auth.userInfo().org === "revdemo";
    this.config = this.$configStore.data.config || {};
    this.declinedReasons = (this.config.DeclinedReason || []).sort();
    this.locations = util.sortWithFixedLeadingElements(this.config.ImmunizationLoc || [], "Unknown");
    const immunizationConfig = await this.getImmunizationConfig();
    if (immunizationConfig) {
      this.immunizationConfig = immunizationConfig;
      this.immunizationTypes = Object.keys(immunizationConfig);
    } else {
      // this is a fallback if imm config is not setup in DB
      this.immunizationConfig = null;
      this.immunizationTypes = (this.config.ImmunizationDesc || []).sort();
      this.vaccineTypes = util.sortWithFixedLeadingElements(this.config.VaccineTypes || [], "Not Available", "Other", "Unknown");
      console.log({ vaccs: this.vaccineTypes, imms: this.immunizationTypes });
    }
    this.setupForm();
    this.dialogBusy = false;
  },
  mounted() {
    this.relocate();
  },
};
</script>
<style scoped>
.individuals .el-select {
  width: 80%;
}
</style>