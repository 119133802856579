<template>
  <div ref="dialogContainer">
    <el-dialog v-model="dialogVisibility" title="Manage Resident Days" class="resident-days-editor" :top="'5%'" size="large" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <p>Please select a unit(s) and a date range to see existing Resident Days, or use the <strong>Import Data</strong> button to upload new Resident Days</p>
      <FiltersSection :showLocation="true" :includeUnits="false" :filterDisplayKeys="['units', 'dateRange']" v-on:change="formatFilters"> </FiltersSection>

      <PageSection title="Existing Resident Days" v-loading="grid.isBusy">
        <el-button class="editing-mode-button" @click="changeEditingMode">{{grid.isEditingMode ? "Confirm Changes" : "Edit Table"}}</el-button>
        <el-table :data="grid.rows" height="300">
          <el-table-column label="Location" width="250" fixed>
            <template scope="scope">
              <div>
                {{ scope.row.location }}
              </div>
            </template>
          </el-table-column>
          <template v-for="(colKey, index) in grid.columns">
            <el-table-column :key="`${index}${colKey}`" :label="colKey" width="100">
              <template scope="scope">
                <div v-if="grid.isEditingMode">
                  <el-input v-model="scope.row[colKey]" />
                </div>
                <div v-else>{{ scope.row[colKey] }} </div>

              </template>
            </el-table-column>
          </template>
          <el-table-column min-width="100" label="Total">
            <template scope="scope">
              {{ scope.row.sum }}
            </template>
          </el-table-column>
        </el-table>
      </PageSection>
      <div class="dialog-footer">
        <el-button type="primary" @click="importCsv">Import Data</el-button>
        <el-button @click="hideEditor">Close</el-button>
      </div>
    </el-dialog>

    <el-dialog v-model="uploader.isVisible" v-loading="uploader.isBusy" title="Upload Days" class="resident-days-uploader" size="small" :top="'5%'" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      <p>
        Based on the unit(s) and date range selected previously the software will generate a template for you to manage resident days.
      </p>
      <p>
        Any values provided in the template will overwrite existing resident days for that location &amp; date. Use a blank cell if you do not want to overwrite any resident days for that location &amp; date.
      </p>
      <p><strong>Do NOT modify the FacID and UnitID columns</strong> they are used for internal purposes.</p>
      <div class="instructions">
        <p>
          <span class="heading">Step 1: Download the template file</span>
          <el-button type="info" @click="writeFile">Download template</el-button>
        </p>
        <p>
          <span class="heading">Step 2: Fill out the template and upload it here:</span>
          <input ref="fileInput" accept=".xlsx" type="file" @change="readFile" />
        </p>
      </div>
      <div class="preview">
        <span class="heading">Step 3: Preview your data:</span>
        <el-table :data="uploader.previewData" border style="width: 100%" height="250">
          <el-table-column fixed prop="location" label="Location" width="250"> </el-table-column>
          <template v-for="colKey in uploader.previewColumns">
            <el-table-column :key="colKey" :label="colKey" width="90">
              <template scope="scope">
                {{ scope.row[colKey] ? scope.row[colKey] : "--" }}
              </template>
            </el-table-column>
          </template>
          <el-table-column label="Total">
            <template scope="scope">
              {{ scope.row.sum }}
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="dialog-footer">
        <el-button @click="commitUpload" type="primary">Upload</el-button>
        <el-button @click="hideUploader">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import auth from "../../auth";
import utils from "../../util";
import moment from "moment";
import XLSX from "xlsx";
import FiltersSection from "../Shared/FiltersSection";
import PageSection from "../Shared/PageSection";

export default {
  name: "resident-days-editor",
  components: {
    FiltersSection,
    PageSection
  },
  props: [],
  data() {
    return {
      dialogVisibility: true,
      dialogBusy: true,
      facilityLookup: {},
      filterValues: {},
      grid: {
        isBusy: true,
        rows: [],
        columns: [],
        isEditingMode: false,
      },
      uploader: {
        isVisible: false,
        isBusy: false,
        previewColumns: [],
        previewData: []
      },
      months: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    };
  },
  methods: {
    changeEditingMode() {
      if (this.grid.isEditingMode) {
        this.modifyTable();
      }
      this.grid.isEditingMode = !this.grid.isEditingMode;
    },
    writeFile() {
      // TODO: use existing data to populate as many cells as possible`
      const { facilities, units, dateRange } = this.filterValues;
      const start = moment(dateRange[0]).startOf("month");
      const end = moment(dateRange[1]).endOf("month");
      const blankRow = new Array(end.diff(start, "months") + 1).fill("");
      const columns = new Array(end.diff(start, "months") + 1).fill(null).map((v, index) => {
        return moment(start)
          .add(index, "months")
          .format("MMM YYYY");
      });
      const locations = facilities.flatMap(id => {
        const facName = this.facilityLookup[id].displayText;
        return this.facilityLookup[id].units
          .filter(u => {
            if (u.code === "PLACE_HOLDER") {
              return;
            }
            if (units !== undefined) {
              return units.includes(u.id);
            }
            return true;
          })
          .map(u => [id, u.id, `${facName} - ${u.displayText}`]);
      });
      // const data = [
      //   `${['FacID (do not edit)', 'UnitID (do not edit)', 'Location', ...columns].join(',')}`,
      //   ...locations.map(locationData => `${[...locationData, ...blankRow].join(',')}`)
      // ].join("\n");
      // var csvFile = new Blob([data], { type: "text/csv" });

      // const link = document.createElement("a");
      // link.href = URL.createObjectURL(csvFile);
      // link.download = 'ResidentDays.csv';
      // document.body.appendChild(link);
      // link.dispatchEvent(
      //   new MouseEvent("click", {
      //     bubbles: true,
      //     cancelable: true,
      //     view: window
      //   })
      // );
      // document.body.removeChild(link);
      const allColumns = ["FacID (do not edit)", "UnitID (do not edit)", "Location", ...columns];
      const data = [...locations.map(locationData => [...locationData, ...blankRow])];
      this.createExcelFile("ResidentDays.xlsx", allColumns, data);
    },
    readFile(e) {
      this.uploader.isBusy = true;
      // const reader = new FileReader();
      // const csv = e.target.files[0];
      // reader.readAsText(csv);
      // reader.onload = e => {
      //   this.uploader.isBusy = false;
      //   const rawText = e.target.result;
      //   const rows = rawText.split(/\r?\n/);
      //   const columns = rows[0].split(",");
      //   this.uploader.previewColumns = columns.slice(3);
      //   this.uploader.previewData = rows.slice(1).map(string => {
      //     const values = string.split(",");
      //     const facId = Number(values[0]);
      //     const unitId = Number(values[1]);
      //     const location = values[2];
      //     const entries = values
      //       .slice(3)
      //       .map((value, i) => [this.uploader.previewColumns[i], value]);
      //     const columnValues = Object.fromEntries(entries);
      //     return {
      //       meta: {
      //         facId,
      //         unitId,
      //         entries
      //       },
      //       location,
      //       ...columnValues,
      //       sum: entries.reduce((prev, current) => prev + Number(current[1]), 0)
      //     };
      //   });
      // };

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.uploader.isBusy = false;
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const json = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { header: 1 });
        const rows = json;
        const columns = rows[0];
        this.uploader.previewColumns = columns.slice(3);
        this.uploader.previewData = rows.slice(1).map(values => {
          const facId = Number(values[0]);
          const unitId = Number(values[1]);
          const location = values[2];
          const entries = values.slice(3).map((value, i) => [this.uploader.previewColumns[i], String(value)]);
          const columnValues = Object.fromEntries(entries);
          return {
            meta: {
              facId,
              unitId,
              entries
            },
            location,
            ...columnValues,
            sum: entries.reduce((prev, current) => prev + Number(current[1]), 0)
          };
        });
      };
      reader.readAsBinaryString(file);

      this.$refs.fileInput.value = null;
    },
    createExcelFile: function(fileName, columns, data) {
      data.unshift(columns);
      const ws = XLSX.utils.aoa_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
      XLSX.writeFile(wb, fileName);
    },
    async commitUpload() {
      const payload = this.uploader.previewData.flatMap(row => {
        const { facId, unitId, entries } = row.meta;
        return entries
          .filter(pair => !!pair[1])
          .map(pair => {
            const date = pair[0];
            const total = Number(pair[1]);
            const month = this.months.indexOf(date.split(" ")[0]) + 1;
            const year = Number(date.split(" ")[1]);
            return {
              facId,
              unitId,
              year,
              month,
              total
            };
          });
      });
      if (!payload.length) {
        this.hideUploader();
        return;
      }
      this.grid.isBusy = true;
      await this.$http
        .post(`${window.CONFIG.treatement_api}/resident-days`, payload)
        .then(response => response.body)
        .catch(() => {
          this.grid.isBusy = false;
          return [];
        });
      this.formatFilters(this.filterValues);
      this.hideUploader();
    },
    async modifyTable() {
      const payload = [];
      this.grid.rows.forEach((row) => {
        Object.keys(row).forEach((key) => {
          if (key !== 'location' && key !== 'sum' && key !== 'facId' && key !== 'unitId') {
            const month = this.months.indexOf(key.split(" ")[0]) + 1;
            const year = Number(key.split(" ")[1]);
            payload.push({
              facId: row.facId,
              unitId: row.unitId,
              month: month,
              year: year,
              total: Number(row[key])
            });
          }
        })
      });
      await this.$http
        .post(`${window.CONFIG.treatement_api}/resident-days`, payload)
        .then(response => response.body)
        .catch(() => {
          this.grid.isBusy = false;
          return [];
        });
      this.convertObjectsToRows(payload);
    },
    importCsv() {
      this.uploader.isVisible = true;
    },
    formatFilters(rawFilters) {
      this.filterValues = { ...rawFilters };
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "dateRange":
                v = [
                  moment(v[0])
                    .startOf("month")
                    .format(),
                  moment(v[1])
                    .endOf("month")
                    .format()
                ];
                break;
            }
            return [k, v];
          })
      );

      filterValues.dateRangeStart = filterValues.dateRange[0];
      filterValues.dateRangeEnd = filterValues.dateRange[1];
      delete filterValues.dateRange;
      this.refreshData(filterValues);
    },
    hideUploader() {
      this.uploader = {
        isVisible: false,
        isBusy: false,
        previewColumns: [],
        previewData: []
      };
    },
    convertObjectsToRows(records) {
      const rows = new Map();
      const columns = new Set();
      records.forEach(obj => {
        const { facId, unitId, month, year, total } = obj;
        const column = moment([year, month - 1]).format("MMM YYYY");
        const unitName = this.facilityLookup[facId].units.filter(u => u.id === unitId)[0].displayText;
        const location = `${this.facilityLookup[facId].displayText} - ${unitName}`;
        const values = rows.get(location) || { sum: 0 };
        values[column] = total;
        values.facId = facId;
        values.unitId = unitId;
        values.sum += total;
        rows.set(location, values);
        columns.add(column);
      });
      this.grid.rows = Array.from(rows.entries())
        .map(entry => {
          return {
            location: entry[0],
            ...entry[1]
          };
        })
        .sort((a, b) => a.location.localeCompare(b.location));
      this.grid.columns = Array.from(columns.values());
    },
    async refreshData(filters) {
      this.grid.isBusy = true;
      const therapyDays = await this.$http
        .post(`${window.CONFIG.treatement_api}/resident-days/search`, {
          ...filters
        })
        .then(response => response.body)
        .catch(() => {
          this.grid.isBusy = false;
          return [];
        });
      this.convertObjectsToRows(therapyDays);
      this.grid.isBusy = false;
    },
    hideEditor() {
      this.$emit("hide");
    },
    refreshEditor() {
      this.$emit("refresh");
    }
  },
  async created() {
    this.dialogBusy = false;
    this.grid.isBusy = false;
    this.facilityLookup = utils.arrayToObj(this.$configStore.data.organization[0].sites, "id");
  },
  async mounted() {
    if (this.$refs.dialogContainer) {
      document.body.append(this.$refs.dialogContainer);
    }
  }
};
</script>
<style>
.resident-days-editor .editing-mode-button {
  position: relative;
  float: right;
  margin-right: 10px;
  margin-top: -34px;
  padding: 6px 10px 6px 10px;
  z-index: 15; /* must be greater than 10 */
}
.resident-days-editor .el-dialog.el-dialog--large {
  width: 90vw !important;
}
.resident-days-editor .el-dialog__body {
  padding: 5px;
}
.resident-days-editor .el-dialog__body .el-row {
  line-height: normal;
}
.resident-days-uploader .instructions {
  display: flex;
}
.resident-days-uploader .heading {
  font-size: 1.25em;
  font-weight: bold;
  margin-bottom: 10px;
  display: inline-block;
}
</style>
