<template>
  <div class="surveillance" v-loading.fullscreen.lock="loading || loadingReports">
    <filters-section :showLocation="true" :showCurrentLocationToggle="true" :modifiedDatepickerShortcuts="filtersSection.datepickerShortcuts" :filterDisplayKeys="filtersSection.filterKeys" :moreFilters="filtersSection.additionalFilters" :dateRangeStart="filtersSection.defaultDateStart" :defaultValues="filtersSection.defaultValues" v-on:change="formatFilters">
      <div slot="infectionOnsetDate-append">
        <el-button :plain="true" class="filter-append" size="mini" @click="useCreatedDateFilter">Use Created Date</el-button>
      </div>
      <div slot="createdDate-append">
        <el-button :plain="true" class="filter-append" size="mini" @click="useOnsetDateFilter">Use Infection Onset Date</el-button>
      </div>
    </filters-section>
    <!-- Detail View -->
    <el-dialog top="75px" :title="clientName(selectedCase)" v-if="selectedCase" v-model="dialogFormVisible" size="large" v-on:close="reloadCases" :close-on-click-modal="false" :close-on-press-escape="false">
      <infection-case-detail :clientName="clientName(selectedCase)" :selectedCase="selectedCase" @dismiss="reloadCases"></infection-case-detail>
    </el-dialog>

    <el-dialog title="Delete" v-model="dialogConfirmDeleteVisible" v-if="selectedCaseToDelete" class="delete-dialog">
      <div>
        <el-row>
          <el-col :span="5"><span>Infection Site: </span></el-col>
          <el-col :span="16">
            <span class="color-code-label-0" v-if="selectedCaseToDelete.symptomType === 'GI'"></span>
            <span class="color-code-label-2" v-else-if="selectedCaseToDelete.symptomType === 'UTI'"></span>
            <span class="color-code-label-1" v-else-if="selectedCaseToDelete.symptomType === 'Respiratory'"></span>
            <span class="color-code-label-3" v-else></span>
            {{ selectedCaseToDelete.symptomType }}
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogConfirmDeleteVisible = false;
            selectedCaseToDelete = null;
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="confirmDeleteCase()">Confirm</el-button>
      </span>
    </el-dialog>
    <!-- DETAIL -->
    <!-- LISTING -->
    <div class="section-header">
      <el-row
        ><span>Case Detail - Total: {{ symptomDetailTotal }}</span>
        <div class="case-filter">
          <el-button :disabled="!canModify" class="case-export-button" :plain="true" type="primary" size="mini" @click="createInfectionCase()"> + New Case</el-button>
          <el-select class="case-filter-select" size="mini" v-model="sortedBy" placeholder="Status" v-on:change="loadCases">
            <el-option label="Sort by Onset Date" value="onsetDate"> </el-option>
            <el-option label="Sort by Onset Location" value="onsetLocation"> </el-option>
            <el-option label="Sort by Current Location" value="currentLocation"> </el-option>
            <el-option label="Sort by First Name" value="firstName"> </el-option>
            <el-option label="Sort by Last Name" value="lastName"> </el-option>
            <el-option label="Sort by Created" value="created"> </el-option>
            <el-option label="Sort by Updated" value="updated"> </el-option>
          </el-select>
          <el-select class="case-filter-select" size="mini" v-model="symptomDetailPageSize" placeholder="Choose tags for your article">
            <el-option v-for="item in symptomDetailPageSizeOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>
          <el-button class="case-export-button" :disabled="$store.getters.reportsIsBusy" :plain="true" type="primary" size="mini" @click="lineListReport()">Surveillance Report</el-button>
          <el-button class="case-export-button" :plain="true" type="primary" size="mini" @click="exportExcel()">Export Data</el-button>
          <el-pagination small layout="prev, pager, next" :current-page="symptomDetailPage" :page-count="symptomDetailPageTotal" v-on:current-change="reloadPage"> </el-pagination>
        </div>
      </el-row>
    </div>
    <el-table :data="cases" v-if="!loadingReports" style="width: 100%" v-on:row-click="selectedRow">
      <el-table-column fixed label=" " width="100">
        <template slot-scope="scope">
          <div style="padding: 0.5em">
            <div :span="4" class="profile-row-photo" v-if="scope.row.client"><img :src="clientPhoto(scope.row.client)" /></div>
            <div :span="4" class="profile-row-photo" v-else><img src="../assets/user.png" /></div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Name (MRN)" fixed width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.client">{{ clientName(scope.row) }}<br />({{ isStaff(scope.row.client) ? scope.row.client.staffId : $configStore.patientIdType() ? scope.row.client[$configStore.patientIdType()] : scope.row.client.patientId }})</span>
          <span v-else>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Infection Site/Type" width="160">
        <template slot-scope="scope">
          <span class="color-code-label-0" v-if="scope.row.symptomType === 'GI'"></span>
          <span class="color-code-label-2" v-else-if="scope.row.symptomType === 'UTI'"></span>
          <span class="color-code-label-1" v-else-if="scope.row.symptomType === 'Respiratory'"></span>
          <span class="color-code-label-3" v-else></span>
          {{ scope.row.symptomType || scope.row.infectionSite }} {{ scope.row.infectionType ? " / " + scope.row.infectionType : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Status" width="70">
        <template slot-scope="scope">
          <img class="symptom-status-image" :src="statusImage(scope.row)" />
        </template>
      </el-table-column>
      <el-table-column label="Symptoms" min-width="160">
        <template slot-scope="scope">
          <el-tag v-for="d in sortedSymptom(scope.row.symptoms)" :key="d.id + d.displayText" :type="tagType(d)">{{ d.displayText }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="Precautions" min-width="160">
        <template slot-scope="scope">
          <div v-if="clients[scope.row.client.id]">
            <el-tag :type="precautionIsActive(d) ? 'danger' : 'gray'" :key="d.precautionId" v-for="d in filterPrecautions(clients[scope.row.client.id].precautionDetails)">{{ precautionById(d.precautionId) }}</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Outcome" width="200">
        <template scope="scope">
          <div>{{ scope.row.outcome || "Not Resolved" }}{{ scope.row.outcome && scope.row.outcomeSubType ? ` / ${scope.row.outcomeSubType}` : "" }}</div>
        </template>
      </el-table-column>
      <el-table-column v-if="sites && sites.length > 1" label="Facility" width="160">
        <template slot-scope="scope">
          {{
            isStaff(scope.row.client)
              ? scope.row.client.facilities
                  .map(facilityNameByFacilityId)
                  .filter((site) => site)
                  .join(", ")
              : facilityNameByUnitId(scope.row.unitId)
          }}
        </template>
      </el-table-column>
      <el-table-column label="Current Location" width="160">
        <template slot-scope="scope">
          {{ scope.row.client.location || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Onset Location" width="160">
        <template slot-scope="scope">
          {{ scope.row.location || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Primary Organism" width="150">
        <template slot-scope="scope">
          {{ scope.row.primaryOrganism || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Result" width="120">
        <template slot-scope="scope">
          {{ scope.row.primaryOrganism ? labResult(scope.row) : "" }}
        </template>
      </el-table-column>
      <el-table-column label="Treatment" width="250">
        <template slot-scope="scope">
          <div class="rx" v-for="rx in scope.row.prescriptions" :key="rx">{{ rx }}</div>
        </template>
      </el-table-column>
      <el-table-column label="Origin" width="150">
        <template slot-scope="scope">
          {{ originById(scope.row.origin) }}
        </template>
      </el-table-column>

      <el-table-column fixed="right" label="Onset Date" prop="onsetDate" width="100">
        <template slot-scope="scope" v-if="scope.row.onsetDate">
          {{ scope.row.onsetDate | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="" prop="active" width="100">
        <template slot-scope="scope">
          <el-button :disabled="!canModify" :plain="true" size="mini" v-if="!scope.row.resolutionDate" @click="resolveInfectionCase(scope.row)">
            <Icon :iconKey="'resolve'" :description="'Resolve Infection Case'" />
          </el-button>
          <el-button :disabled="!canModify" v-else :plain="true" size="mini" @click="unresolveInfectionCase(scope.row)">
            <Icon :iconKey="'unresolve'" :description="'Unresolve Infection Case'" />
          </el-button>
          <el-button :disabled="!canModify" :plain="true" size="mini" @click="deleteCase(scope.$index, scope.row)">
            <Icon :iconKey="'delete'" :description="'Delete Infection Case'" />
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="Updated By" prop="updatedBy" width="100">
        <template slot-scope="scope">
          {{ scope.row.updatedBy || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Updated" prop="updated" width="100">
        <template slot-scope="scope" v-if="scope.row.updated">
          {{ scope.row.updated | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
      <el-table-column label="Created By" prop="createdBy" width="100">
        <template slot-scope="scope">
          {{ scope.row.createdBy || "" }}
        </template>
      </el-table-column>
      <el-table-column label="Created" prop="created" width="100">
        <template slot-scope="scope" v-if="scope.row.created">
          {{ scope.row.created | moment($configStore.dateFormat()) }}
        </template>
      </el-table-column>
    </el-table>
    <progress-overlay v-if="excelExportResultsVisible" :progress="excelExportResultsProgress" v-on:progress-cancelled="cancelExcelExport" title="Download In Progress"> </progress-overlay>
    <!-- Infection Case Creator -->
    <infection-case-creator
      v-if="createInfectionCaseDialogVisible"
      v-on:creator-close="cancelInfectionCaseCreator"
      v-on:creator-save="
        cancelInfectionCaseCreator();
        reloadCases();
      "
      v-on:creator-error="cancelInfectionCaseCreator()"
    ></infection-case-creator>
    <!-- Resolve Infection Case -->
    <resolve-infection-case v-if="showResolutionDialog" :infectionCase="selectedCaseToResolve" v-on:resolution-save="closeResolveInfectionCase" v-on:resolution-cancel="cancelResolveInfectionCase" v-on:resolution-close="closeResolveInfectionCase"></resolve-infection-case>

    <!-- Unresolve Infection Case -->
    <unresolve-infection-case v-if="showUnresolveDialog" :infectionCase="selectedCaseToUnresolve" v-on:unresolve-save="closeUnresolveInfectionCase" v-on:unresolve-cancel="cancelUnresolveInfectionCase"></unresolve-infection-case>

    <!-- Export Error Dialog -->
    <el-dialog title="Error, please try again" v-model="excelExportErrorVisible" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
      {{ excelExportErrorMessage }}
      <div class="dialog-footer">
        <el-button @click="excelExportErrorVisible = false">Close</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import auth from "../auth";
import util from "../util";
import transformer from "../transformer";
import api from "../services/restClient";
import { initLineListReportGenerator } from "../services/reportClient";

import moment from "moment";
import LocationFilter from "../components/LocationFilter/LocationFilter";
import InfectionCaseDetail from "../components/InfectionCaseDetail";
import CreateInfectionCase from "../components/IS/CreateInfectionCase";
import ProgressOverlay from "../components/ProgressBarOverlay";
import Icon from "../components/Shared/Icon";
import ResolveInfectionCase from "../components/IS/ResolveInfectionCase";
import UnresolveInfectionCase from "../components/IS/UnresolveInfectionCase";
import FiltersSection from "./Shared/FiltersSection";
import XLSX from "xlsx";

export default {
  name: "surveillance",
  components: {
    "infection-case-detail": InfectionCaseDetail,
    "infection-case-creator": CreateInfectionCase,
    "location-filter": LocationFilter,
    "progress-overlay": ProgressOverlay,
    "resolve-infection-case": ResolveInfectionCase,
    "unresolve-infection-case": UnresolveInfectionCase,
    Icon,
    "filters-section": FiltersSection,
  },
  data: function () {
    return {
      cancelExcelExportProgress: false,
      excelExportResultsVisible: false,
      excelExportResultsProgress: 0,
      excelExportErrorVisible: false,
      excelExportErrorMessage: "",
      filtersSection: {
        filterKeys: ["infectionOnsetDate", "useCurrentLocation", "infectionSites", "resolutions", "origins", "caseStatus", "clientType", "clientStatus", "firstName", "lastName"],
        filters: {},
        additionalFilters: ["primaryOrganisms", "medications", "precautions"],
        defaultValues: {
          resolutions: ["Not Resolved"],
        },
        defaultDateStart: moment().subtract(30, "days").toDate(),
        datepickerShortcuts: [],
      },
      loadingReports: false,
      canModify: false,
      fixClick: false,
      showResolutionDialog: false,
      showUnresolveDialog: false,
      loading: true,
      firstLoad: false,
      cases: [],
      locations: [],
      precautions: [],
      symptomTypes: [],
      symptoms: [],
      clients: {},
      clientList: [],
      sortedBy: "onsetDate",
      origins: [],
      statusImageMap: {
        GI: "bug2",
        UTI: "bug3",
        Respiratory: "bug1",
        Skin: "bug4",
      },
      caseActiveTab: "1",
      selectedCase: null,
      selectedClient: null,
      selectedUnit: 0,
      selectedSite: 0,
      selectedCaseToDelete: null,
      selectedCaseToResolve: null,
      selectedCaseToUnresolve: null,
      selectedContact: 0,
      dialogFormVisible: false,
      createInfectionCaseDialogVisible: false,
      dialogConfirmDeleteVisible: false,
      subStatusMap: {
        GI: ["Noro Virus", "CDI", "GE"],
        UTI: ["ABUTI", "SUTI", "ASB"],
        Respiratory: ["Upper/Common Cold", "Lower/Pnem/Bron", "Flu - Influezna Like", "SINU"],
      },
      symptomDetailPage: 1,
      symptomDetailPageSize: 5,
      symptomDetailPageTotal: 0,
      symptomDetailTotal: 0,
      symptomDetailPageSizeOptions: [
        {
          label: "5 items per page",
          value: 5,
        },
        {
          label: "10 items per page",
          value: 10,
        },
        {
          label: "20 items per page",
          value: 20,
        },
      ],
      filteredSite: {},
      labReports: {},
      filterPeriods: "week",
      filterSymptomTypes: [],
      filteredSiteSubType: 0,
      siteAndTypeOptions: [],
      availableUnits: [],
      organisms: [],
      treatments: [],
      units: [],
      sites: [],
      organization: [],
      graphPeriodFilter: "By Week",
      graphDisplayFilter: "By Infection Site",
      medicationsLookup: {},
    };
  },
  methods: {
    formatFilters(rawFilters) {
      const filterValues = Object.fromEntries(
        Object.entries(rawFilters)
          .filter(([k, v]) => v !== 0)
          .map(([k, v]) => {
            switch (k) {
              case "facilities":
                k = "facIds";
                break;
              case "units":
                k = "unitIds";
                break;
              case "infectionSites":
                v = v[0] === 0 ? ["all"] : [v[0].type];
                break;
              case "resolutions":
                k = "outcomes";
                v = v[0] === 0 ? ["all"] : [v[0]];
                break;
              case "medications":
                k = "medicationIds";
                break;
              case "precautions":
                k = "precautionIds";
                break;
            }
            return [k, v];
          })
      );
      this.filtersSection.filters = { ...filterValues };
      if (filterValues.createdDate && filterValues.createdDate[0]) {
        this.filtersSection.filters.createDateFrom = moment(filterValues.createdDate[0]).startOf("day").format();
        this.filtersSection.filters.createDateTo = moment(filterValues.createdDate[1]).endOf("day").format();
      }
      if (filterValues.infectionOnsetDate && filterValues.infectionOnsetDate[0]) {
        this.filtersSection.filters.onsetDateFrom = moment(filterValues.infectionOnsetDate[0]).startOf("day").format();
        this.filtersSection.filters.onsetDateTo = moment(filterValues.infectionOnsetDate[1]).endOf("day").format();
      }
      delete this.filtersSection.filters.createdDate;
      delete this.filtersSection.filters.infectionOnsetDate;
      if (rawFilters.infectionSites && rawFilters.infectionSites[1]) this.filtersSection.filters.infectionSiteSubTypes = [rawFilters.infectionSites[1]];
      if (rawFilters.resolutions && rawFilters.resolutions[1]) this.filtersSection.filters.outcomeSubTypes = [rawFilters.resolutions[1]];
      this.loadCases();
    },
    hasFacilityTypeFilter() {
      return auth.userInfo().roles.indexOf("ROLE_LTC") >= 0 && auth.userInfo().roles.indexOf("ROLE_RET") >= 0;
    },
    getLocation: function (client) {
      let result = "";
      if (!this.isStaff(client)) {
        if (client.location) {
          result = client.location;
        } else {
          result = this.unitById(client.unitId);
        }
      }
      return result;
    },
    // use created date filter instead of onset date
    useCreatedDateFilter() {
      let index = this.filtersSection.filterKeys.indexOf("infectionOnsetDate");

      if (index !== -1) {
        this.filtersSection.filterKeys.splice(index, 1, "createdDate");
      }
      const filters = {
        ...this.filtersSection.filters,
        createdDate: [this.filtersSection.defaultDateStart, moment().toDate()],
      };
      delete filters.infectionOnsetDate;
      this.formatFilters(filters);
    },
    // use onset date filter instead of created date
    useOnsetDateFilter() {
      let index = this.filtersSection.filterKeys.indexOf("createdDate");

      if (index !== -1) {
        this.filtersSection.filterKeys.splice(index, 1, "infectionOnsetDate");
      }
      const filters = {
        ...this.filtersSection.filters,
        infectionOnsetDate: [this.filtersSection.defaultDateStart, moment().toDate()],
      };
      delete filters.createdDate;
      this.formatFilters(filters);
    },
    // resolve init, close, cancel
    resolveInfectionCase(row) {
      this.selectedCaseToResolve = row;
      this.showResolutionDialog = true;
    },
    closeResolveInfectionCase() {
      this.selectedCaseToResolve = null;
      this.showResolutionDialog = false;
      this.reloadCases();
    },
    cancelResolveInfectionCase() {
      this.selectedCaseToResolve = null;
      this.showResolutionDialog = false;
    },
    // unresolve init, cancel, save
    unresolveInfectionCase(row) {
      this.selectedCaseToUnresolve = row;
      this.showUnresolveDialog = true;
    },
    cancelUnresolveInfectionCase() {
      this.selectedCaseToUnresolve = null;
      this.showUnresolveDialog = false;
    },
    closeUnresolveInfectionCase() {
      this.selectedCaseToUnresolve = null;
      this.showUnresolveDialog = false;
      this.reloadCases();
    },
    isAdminStaff: function () {
      return auth.userInfo().roles.indexOf("ROLE_ADMIN_STAFF") >= 0;
    },
    reloadCases: function () {
      this.dialogFormVisible = false;
      this.selectedCase = null;
      this.loadCases();
    },
    filterPrecautions: function (precautions) {
      let vm = this;
      return (precautions || []).filter((p) => vm.precautionById(p.precautionId));
    },
    updatePersonalNotes(newNote) {
      console.log(newNote);
      if (this.clients[this.selectedClient.client.id]) {
        var clientData = this.clients[this.selectedClient.client.id];
        clientData.notes.splice(0, 0, newNote);
      }
    },
    handleChange(value) {
      console.log(value);
      this.filteredSite = value[0];
      this.filteredSiteSubType = value[1] || 0;

      this.symptomTypeUpdated(value[0]);
    },
    getsubStatusMap: function (caseData) {
      // console.log(caseData)
      if (caseData && caseData.symptomType && caseData.symptomType.subTypes) {
        // console.log(this.subStatusMap[caseData.symptomType.text])
        // console.log('getsubStatusMap subtypes')
        return caseData.symptomType.subTypes;
      }

      // console.log('getsubStatusMap')
      return [];
    },
    async loadCases() {
      if (!this.loading) {
        this.loading = true;
      }
      const json = await this.$http
        .post(`${window.CONFIG.infection_api}/search`, {
          ...this.filtersSection.filters,
          orderBy: this.sortedBy,
          page: this.symptomDetailPage,
          size: this.symptomDetailPageSize,
        })
        .then((resp) => resp.json())
        .catch((err) => {
          this.loading = false;
          return {
            page: 0,
            content: [],
            totalElements: 0,
          };
        });
      const cases = json.content;
      this.symptomDetailPageTotal = json.totalPages;
      this.symptomDetailTotal = json.totalElements;
      if (cases.length) {
        let caseTotal = cases.length;
        let processed = 0;
        this.labReports = {};
        this.loadingReports = true;
        let remainingReports = caseTotal;
        cases.forEach(async (c) => {
          if (c.origin == null) {
            c.origin = "Not Selected";
          }
          c.symptoms.sort(function (a, b) {
            return a.colorCode > b.colorCode;
          });
          c.otherSymptoms = [];
          api.getCustomSymptomForDetail(c.id, (r) => {
            c.otherSymptoms = r.body.otherSymptoms || [];
            processed = processed + 1;

            if (processed === caseTotal) {
              this.cases = cases;
            }
          });

          c.client.precautionDetails = await this.$http
            .get(`${window.CONFIG.precaution_api}`, {
              params: {
                clientId: c.client.id,
                symptomDetailId: c.id,
              },
            })
            .then((resp) => resp.json())
            .then((json) => json.content);

          c.prescriptions = [];

          const prescriptions = await this.$http
            .get(`${window.CONFIG.treatement_api}/prescriptions`, {
              params: {
                clientId: c.client.id,
                symptomDetailId: c.id,
              },
            })
            .then((resp) => resp.json());
          c.prescriptions = this.transformMedications(prescriptions.content);

          api.getLabReport(c.id, (response) => {
            if (response) {
              this.labReports[c.id] = response.body || [];
            }
            remainingReports--;
            if (remainingReports === 0) {
              this.loadingReports = false;
            }
          });
        });
      } else {
        this.cases = [];
        this.symptomDetailPage = 1;
        this.symptomDetailPageTotal = 0;
        this.symptomDetailTotal = 0;
      }
      this.firstLoad = true;
      this.loading = false;
    },
    clientName: function (symptomDetail) {
      var clientData = symptomDetail.client;
      this.clients[symptomDetail.client.id] = clientData;
      return this.getClientName(clientData);
    },
    getClientName: function (clientData) {
      var firstName = clientData.firstName ? clientData.firstName + " " : "";
      var middleName = clientData.middleName ? clientData.middleName + " " : "";
      var lastName = clientData.lastName ? clientData.lastName : "";

      // console.log(JSON.stringify(clientData, ' ', 2))
      return firstName + middleName + lastName;
    },
    getIdForItem(item) {
      if (item.clientType !== "Staff") {
        return this.$configStore.patientIdType() ? item[this.$configStore.patientIdType()] : item.patientId;
      } else {
        return item.staffId;
      }
    },
    sortedSymptom(symptoms) {
      if (!symptoms || symptoms.length <= 1) {
        return symptoms;
      }
      let sortedSymptoms = [];
      symptoms.forEach((s) => {
        sortedSymptoms.push(s);
      });
      sortedSymptoms.sort(function (a, b) {
        let compA = a.classType || "";
        let compB = b.classType || "";

        if (compA === "GI") compA = "a" + a.displayText;
        else if (compA === "Respiratory") compA = "b" + a.displayText;
        else if (compA === "UTI") compA = "c" + a.displayText;
        else compA = "d" + a.displayText;

        if (compB === "GI") compB = "a" + b.displayText;
        else if (compB === "Respiratory") compB = "b" + b.displayText;
        else if (compB === "UTI") compB = "c" + b.displayText;
        else compB = "d" + b.displayText;

        let x = compA.toLowerCase();
        let y = compB.toLowerCase();
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });

      return sortedSymptoms;
    },
    reloadPage: function (page) {
      if (this.symptomDetailPage !== page) {
        this.symptomDetailPage = page;
        if (this.firstLoad) {
          this.loadCases();
        }
      }
    },
    loadImage: function (detail, callback) {
      var vm = this;
      if (this.clients[detail.client.id] || this.clients[detail.client.id] === false) {
        callback();
        return;
      }
      this.clients[detail.client.id] = false;
      this.$http.get(window.CONFIG.api + "/clients/" + detail.client.id).then(
        (response) => {
          // console.log(JSON.stringify(response.body, ' ', 2))
          vm.$set(vm.clients, detail.client.id, response.body);
          vm.$forceUpdate();
          callback();
        },
        () => {
          // error
          callback();
        }
      );
    },
    statusImage: function (detail) {
      var result = "";
      var filename;
      if (detail.symptomType) {
        filename = this.statusImageMap[detail.symptomType];
        if (!detail.status) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      if (result === "") {
        filename = "bug4";
        if (!detail.status) {
          filename = filename + "s";
        }
        result = "/static/" + filename + ".png";
      }

      return result;
    },
    tagType: function (type) {
      if (type.colorCode === 0) {
        return "success";
      } else if (type.colorCode === 1) {
        return "primary";
      } else if (type.colorCode === 2) {
        return "warning";
      } else if (type.colorCode === 3) {
        return "danger";
      }
      return "gray";
    },
    selectedRow: function (row, event, column) {
      if (column.property === "active") {
        return;
      }

      this.selectedCase = transformer.mapInfectionCaseToLegacyModel(JSON.parse(JSON.stringify(row)));
      console.log("=-=-=-=-= this.selectedCase =-=-=-=-=");
      console.log(this.selectedCase);
      console.log(this.selectedCase.client);
      this.selectedClient = this.selectedCase.client;

      var vm = this;
      vm.caseActiveTab = "1";
      vm.$nextTick(function () {
        // console.log(vm.caseActiveTab)
        vm.dialogFormVisible = true;
      });
      api.getLocationsFromOcc(this.selectedCase.client.id, (res) => {
        console.log("----- locations -----");
        console.log(res.body);
        vm.locations = res.body;
      });
    },
    getTreatments: function (caseData) {
      let result = "";

      caseData.treatmentDetails.forEach((d) => {
        if (result.length > 0) {
          result = result + ", ";
        }
        if (d.customTreatment) {
          result = result + d.customTreatment;
        } else if (d.treatment && d.treatment.displayText) {
          result = result + d.treatment.displayText;
        }
      });

      if (result === "") {
        result = "Not Selected";
      }

      return result;
    },
    transformMedications: function (prescriptions) {
      const meds = this.medicationsLookup;
      return prescriptions.map((med) => {
        const { frequency, dosage, medicationId } = med;
        const medication = meds[medicationId]?.displayText;
        return `${medication || ""} ${dosage || ""} ${frequency || ""}`;
      });
    },
    labResult: function (caseData) {
      let result = "";
      let reports = this.labReports[caseData.id] || [];
      console.log("reports read", reports);
      reports.forEach((l) => {
        if (l.organism === caseData.primaryOrganism) {
          result = l.result || "";
        }
      });

      return result;
    },
    precautionIsActive(row) {
      const { startDate, endDate } = row;
      // precaution is active when there's no end date
      if (!endDate) {
        return true;
      }
      // also active if between the start and end dates
      const now = new Date();
      return now >= new Date(startDate) && now <= new Date(endDate);
    },
    unitById: function (unitId) {
      var unitName = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          unitName = u.displayText;
        }
      });

      return unitName;
    },
    facilityNameByUnitId: function (unitId) {
      var name = "";
      this.units.forEach(function (u) {
        if (u.id === unitId) {
          name = u.site.displayText;
        }
      });

      return name;
    },
    facilityNameByFacilityId(facId) {
      return this.sites.filter((s) => s.id === facId).map((s) => s.displayText)[0];
    },
    originById: function (originId) {
      var originName = "Not Selected";
      this.origins.forEach(function (o) {
        if (o.value === originId && o.value !== 0) {
          originName = o.label;
        }
      });
      return originName;
    },
    unitByName: function (unitName) {
      var unit = null;
      this.units.forEach(function (u) {
        if (u.displayText === unitName) {
          unit = u;
        }
      });

      return unit;
    },
    precautionById: function (precautionId) {
      var name = "";
      this.precautions.forEach(function (p) {
        if (p.id === precautionId) {
          name = p.displayText;
        }
      });

      return name;
    },
    clientPhoto: function (client) {
      if (client && client.photoUrl) {
        var f = client.photoUrl;
        if (f.indexOf("/") !== -1) {
          return window.CONFIG.api + "/" + f + "?access_token=" + auth.getToken();
        }

        return "/static/" + (client.photoUrl ? client.photoUrl : "user") + ".png";
      }

      return "/static/user.png";
    },
    saveSymtomDetail: function (symptomDetail, callback) {
      var vm = this;
      let otherSymptoms = symptomDetail.otherSymptoms ? symptomDetail.otherSymptoms.slice() : [];
      let sId = symptomDetail.id;
      console.log("=-=-=-=-= saveSymtomDetail =-=-=-=-=");
      api.updateSymptomDetail(symptomDetail, function () {
        api.createCustomSymptomsExt(
          {
            symptomDetailId: sId,
            otherSymptoms: otherSymptoms,
          },
          (r) => {
            vm.loadCases();
            vm.dialogFormVisible = false;
            vm.selectedCase = null;
            vm.locations = [];
            console.log(r);
            if (callback) {
              callback();
            }
          }
        );
      });
    },
    reverse: function (array) {
      return array.slice().reverse();
    },
    isStaff: function (client) {
      if (client) {
        return client.clientType === "Staff";
      } else {
        return false;
      }
    },
    exportExcel: async function () {
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const vm = this;
      console.clear();

      let totalNetworkCalls = 0;
      let finishedNetworkCalls = 0;

      const columns = [
        "Case Id",
        "Patient Id",
        "Given Name",
        "Middle Name",
        "Family Name",
        "Confirmed",
        "OnSet Date",
        "Infection Site",
        "Infection Site Type",
        "Symptoms",
        "Precautions",
        "Facility",
        "Location",
        "Origin",
        "Contact",
        "Primary Organism",
        "Result",
        "Culture Date",
        "Organism",
        "Treatment",
        "Treatment Start Date",
        "Treatment End Date",
        "Staff",
        "Staff Isolation",
        "Staff Isolation Start Date",
        "Staff Isolation End Date",
        "Position",
        "Resolved",
        "Resolved Date",
        "Outcome",
        "Outcome Detail",
        "Family Notified",
        "Manager Notified",
        "Physician/Public Health Notified",
        "Staff case reported to MoL",
        "Updated By",
        "Updated",
        "Created By",
        "Created",
        "NOTES",
      ];
      const fetchDataForColumns = async (page) => {
        const casesResponse = await this.$http
          .post(`${window.CONFIG.infection_api}/export`, {
            ...this.filtersSection.filters,
            orderBy: this.sortedBy,
            size: this.symptomDetailTotal || 500,
            page: 1,
          })
          .then((response) => response.json())
          .catch((err) => {
            // requested size is higher than allowed limit
            if (err?.status === 422) {
              this.excelExportErrorMessage = err?.body;
            } else {
              this.excelExportErrorMessage = "An error occurred, please reload the page and try again.";
            }
            this.excelExportErrorVisible = true;
            this.cancelExcelExportProgress = true;
            this.excelExportResultsVisible = false;
            return;
          });
        if (!casesResponse) {
          return;
        }
        finishedNetworkCalls++;
        totalNetworkCalls = casesResponse.totalElements * 2 + casesResponse.totalPages;

        this.excelExportResultsProgress = Math.round(100 * (finishedNetworkCalls / totalNetworkCalls));

        for (let i = 0; i < casesResponse.content.length; i++) {
          const labRecordsResponse = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${casesResponse.content[i].id}/labs`);
          finishedNetworkCalls++;
          this.excelExportResultsProgress = Math.round(100 * (finishedNetworkCalls / totalNetworkCalls));
          casesResponse.content[i].labRecords = labRecordsResponse.body;
          vm.labReports[casesResponse.content[i].id] = labRecordsResponse.body || [];

          const treatmentDetailsResponse = await this.$http
            .get(`${window.CONFIG.treatement_api}/prescriptions`, {
              params: {
                clientId: casesResponse.content[i].client.id,
                symptomDetailId: casesResponse.content[i].id,
              },
            })
            .then((resp) => resp.json());

          casesResponse.content[i].medicineDetails = treatmentDetailsResponse.content;

          const precautionDetailsResponse = await this.$http
            .get(window.CONFIG.precaution_api, {
              params: {
                clientId: casesResponse.content[i].client.id,
                symptomDetailId: casesResponse.content[i].id,
              },
            })
            .then((resp) => resp.json());

          casesResponse.content[i].client.precautionDetails = precautionDetailsResponse.content;
          finishedNetworkCalls++;
          this.excelExportResultsProgress = Math.round(100 * (finishedNetworkCalls / totalNetworkCalls));
        }
        return casesResponse;
      };
      const formatDate = (timestamp) => moment(timestamp).format(this.$configStore.dateFormat());
      const formatFac = (_) => (this.isStaff(_.client) ? this.getFacilityNamesForStaff(_.client.facilities).join(", ") : this.getFacilityNameForResident(_.client.facId));
      const getPrimary = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0];
      const getNonPrimary = (_) => _.labRecords.filter((r) => r.organism !== _.primaryOrganism);
      const getCultureDate = (_) => {
        const p = getPrimary(_);
        return p ? formatDate(p.cultureDate) : "";
      };

      const getTreatments = (_) => {
        const values = [
          _.medicineDetails
            .map((med) => {
              const { frequency, dosage, medicationId } = med;
              const medication = !medicationId || !this.medicationsLookup[medicationId] ? this.medicationsLookup[medicationId]?.displayText : "";
              return `${medication} ${dosage} ${frequency}`.trim();
            })
            .join(", "),
          _.medicineDetails.map((med) => (med.startDate ? formatDate(med.startDate) : "")).join(", "),
          _.medicineDetails.map((med) => (med.endDate ? formatDate(med.endDate) : "")).join(", "),
        ];
        return values;
      };

      const convertDataToColumns = (_) => [
        _.id,
        this.getIdForItem(_.client),
        _.client.firstName,
        _.client.middleName,
        _.client.lastName,
        _.status,
        formatDate(_.onsetDate),
        _.symptomType || _.infectionSite,
        _.infectionType,
        _.symptoms.map((s) => s.displayText).join(", "),
        _.client.precautionDetails.map((p) => this.precautionById(p.precautionId)).join(", "),
        formatFac(_),
        _.client.location ? _.client.location : "",
        this.originById(_.origin),
        _.contact,
        _.primaryOrganism,
        _.primaryOrganism ? this.labResult(_) : "",
        getCultureDate(_),
        getNonPrimary(_)
          .map((lr) => lr.organism)
          .join(", "),
        ...getTreatments(_),
        this.isStaff(_.client),
        _.staffIsolation,
        _.isolationStart ? formatDate(_.isolationStart) : "",
        _.isolationEnd ? formatDate(_.isolationEnd) : "",
        _.client.employeeType,
        _.resolved,
        _.resolutionDate ? formatDate(_.resolutionDate) : "",
        _.outcome,
        _.outcomeSubType,
        _.familyNotified,
        _.nextOfNotified,
        _.physicianNotified,
        _.staffCaseNotified,
        _.updatedBy,
        _.updated ? formatDate(_.updated) : "",
        _.createdBy,
        _.created ? formatDate(_.created) : "",
        _.notes.map((n) => n.content).join(", "),
      ];
      let data = [];
      let currentPage = 1;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        if (!json) {
          console.log("excel export error occurred");
          return;
        }
        data = [...data, ...json.content.map(convertDataToColumns)];
        hasMore = currentPage < json.totalPages;
        currentPage++;
      }
      this.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onsetDate - a.onsetDate);
      if (!this.cancelExcelExportProgress) {
        this.createExcelFile("report.xlsx", columns, data);
      }
    },
    createExcelFile: function (fileName, columns, data) {
      // 20 = staff 5 = confirmed
      const staffColumnIndex = columns.indexOf("Staff");
      const confirmColumnIndex = columns.indexOf("Confirmed");
      const confirmedStaff = [...data].filter((row) => row[staffColumnIndex] && row[confirmColumnIndex]);
      const confirmedRes = [...data].filter((row) => !row[staffColumnIndex] && row[confirmColumnIndex]);
      const suspectedStaff = [...data].filter((row) => row[staffColumnIndex] && !row[confirmColumnIndex]);
      const suspectedRes = [...data].filter((row) => !row[staffColumnIndex] && !row[confirmColumnIndex]);
      const all = data;
      confirmedStaff.unshift(columns);
      confirmedRes.unshift(columns);
      suspectedStaff.unshift(columns);
      suspectedRes.unshift(columns);
      all.unshift(columns);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(confirmedRes), "Resident Infections - Confirmed");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(confirmedStaff), "Staff Infections - Confirmed");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(suspectedRes), "Resident Infections - Suspected");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(suspectedStaff), "Staff Infections - Suspected");
      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(all), "data");
      XLSX.writeFile(wb, fileName);
    },
    cancelExcelExport() {
      this.cancelExcelExportProgress = true;
    },
    getFacilityNameForResident(id) {
      return this.sites.filter((s) => s.id === id)[0].displayText;
    },
    getFacilityNamesForStaff(ids) {
      return this.sites.filter((s) => ids.includes(s.id)).map((s) => s.displayText);
    },

    createInfectionCase() {
      this.createInfectionCaseDialogVisible = true;
    },
    cancelInfectionCaseCreator() {
      this.createInfectionCaseDialogVisible = false;
    },
    deleteCase: function (i, row) {
      // console.log(row)
      // if (!row) {
      //   row = this.cases[i]
      // }
      const legacyCase = transformer.mapInfectionCaseToLegacyModel(row);
      this.selectedCaseToDelete = legacyCase;
      this.dialogConfirmDeleteVisible = true;
    },
    confirmDeleteCase: function () {
      if (this.selectedCaseToDelete) {
        this.selectedCaseToDelete.active = false;
        this.saveSymtomDetail(this.selectedCaseToDelete);
        this.dialogConfirmDeleteVisible = false;
      }
    },
    lineListReport: async function () {
      this.excelExportResultsVisible = true;
      this.excelExportResultsProgress = 0;
      this.cancelExcelExportProgress = false;
      const meds = this.medicationsLookup;
      const fetchDataForColumns = async (page) => {
        const response = await this.$http
          .post(`${window.CONFIG.infection_api}/search`, {
            ...this.filtersSection.filters,
            orderBy: this.sortedBy,
            page,
            size: 30,
          })
          .then((response) => response.json());
        const elements = response.content;
        for (let i = 0; i < elements.length; i++) {
          const labRecordsResponse = await this.$http.get(`${window.CONFIG.api}/symptomDetail/${elements[i].id}/labs`);
          currentElement++;
          this.excelExportResultsProgress = Math.round(100 * (currentElement / (response.totalElements * 3)));

          const immunizationDetailsResponse = await this.$http.get(`${window.CONFIG.api}/clients/${elements[i].client.id}/immunizations`);
          currentElement++;
          this.excelExportResultsProgress = Math.round(100 * (currentElement / (response.totalElements * 3)));

          const treatmentDetailsResponse = await this.$http
            .get(`${window.CONFIG.treatement_api}/prescriptions`, {
              params: {
                clientId: elements[i].client.id,
                symptomDetailId: elements[i].id,
              },
            })
            .then((resp) => resp.json());

          const precautionDetailsResponse = await this.$http
            .get(window.CONFIG.precaution_api, {
              params: {
                clientId: response.content[i].client.id,
                symptomDetailId: response.content[i].id,
              },
            })
            .then((resp) => resp.json());

          currentElement++;
          this.excelExportResultsProgress = Math.round(100 * (currentElement / (response.totalElements * 3)));

          elements[i].labRecords = labRecordsResponse.body;
          this.labReports[elements[i].id] = labRecordsResponse.body || [];
          elements[i].immunizationDetails = immunizationDetailsResponse.body;
          elements[i].medicineDetails = treatmentDetailsResponse.content;
          elements[i].client.precautionDetails = precautionDetailsResponse.content;
        }
        return response;
      };
      const formatDate = (timestamp) => moment(timestamp).format(this.$configStore.dateFormat());
      const formatFac = (_) => (this.isStaff(_.client) ? this.getFacilityNamesForStaff(_.client.facilities).join(", ") : this.getFacilityNameForResident(_.client.facId));
      const getPrimary = (_) => _.labRecords.filter((r) => r.organism === _.primaryOrganism)[0];
      const getCultureDate = (_) => {
        const p = getPrimary(_);
        return p ? formatDate(p.cultureDate) : "";
      };
      const renderMedications = (_) => {
        return _.medicineDetails
          .map((t) => {
            const { startDate, endDate, frequency, dosage, medicationId } = t;
            const medication = meds[medicationId]?.displayText;
            return `${medication || ""} ${dosage || ""} ${frequency || ""}\n${formatDate(startDate)} - ${formatDate(endDate)}`;
          })
          .join("\n");
      };
      const renderTreatments = (_) => {
        _.treatmentDetails.map((t) => {
          return {
            displayText: t.customTreatment || t.treatment?.displayText,
            startDate: formatDate(t.startDate),
            endDate: formatDate(t.endDate),
          };
        });
      };

      const convertDataToColumns = (_) => {
        const c = _.client;
        const patientIdLookupKey = this.$configStore.patientIdType() || "patientId";
        return {
          reportDate: formatDate(Date.now()),
          facility: formatFac(_),
          name: `${c.firstName} ${c.lastName}`,
          id: this.isStaff(c) ? c.staffId : c[patientIdLookupKey],
          gender: c.gender,
          dob: c.dateOfBirth ? formatDate(c.dateOfBirth) : "",
          location: c.location ? c.location : "",
          onsetDate: _.onsetDate ? formatDate(_.onsetDate) : "",
          outcome: _.outcome,
          resolutionDate: _.resolutionDate ? formatDate(_.resolutionDate) : "",
          infectionSite: `${_.infectionSite} ${_.infectionType}`,
          status: _.status ? "confirmed" : "suspected",
          immunizationDetails: _.immunizationDetails.map((d) => `${d.description}${d.vaccineType ? ` (${d.vaccineType}) ` : " "}${formatDate(d.date)}`).join("\n"),
          precautions: c.precautionDetails.map((p) => this.precautionById(p.precautionId)).join("\n"),
          symptoms: _.symptoms.map((s) => s.displayText).join("\n"),
          cultureDate: getCultureDate(_),
          result: _.primaryOrganism ? this.labResult(_) : "",
          organism: _.primaryOrganism,
          treatments: renderMedications(_),
          notes: _.notes.map((n) => n.content).join(", "),
          origin: _.origin,
          notifications: [_.familyNotified ? "Family notified" : undefined, _.nextOfNotified ? "Manager notified" : undefined, _.physicianNotified ? "Public health / physician notified" : undefined, _.staffCaseNotified ? "Staff case reported to MoL" : undefined].filter(Boolean).join("\n"),
          labs: _.labRecords.map((l) => {
            return {
              procedure: l.procedure,
              result: l.result,
              organism: l.organism,
              cultureDate: formatDate(l.cultureDate),
              collectionDate: formatDate(l.collectionDate),
            };
          }),
          staffIsolation: this.isStaff(c) ? _.staffIsolation : "",
          staffIsolationStart: this.isStaff(c) ? formatDate(_.isolationStart) : "",
          staffIsolationEnd: this.isStaff(c) ? formatDate(_.isolationEnd) : "",
        };
      };

      let data = [];
      let currentPage = 1;
      let currentElement = 0;
      let hasMore = true;
      while (hasMore) {
        if (this.cancelExcelExportProgress) {
          break;
        }
        const json = await fetchDataForColumns(currentPage);
        data = [...data, ...json.content.map(convertDataToColumns)];
        hasMore = currentPage !== json.totalPages;
        currentPage++;
      }
      this.excelExportResultsVisible = false;
      data = data.sort((a, b) => b.onsetDate - a.onsetDate);
      if (!this.cancelExcelExportProgress) {
        const template = this.$configStore.getLineListReports().infectionSurveillance;
        initLineListReportGenerator(template, data);
      }
    },
  },
  created: function () {
    const shortcuts = util.datepickerShortcuts();
    const allDatesShortcut = {
      text: "All dates",
      onClick(picker) {
        picker.$emit("pick", "");
      },
    };
    shortcuts.unshift(allDatesShortcut);
    this.filtersSection.datepickerShortcuts = shortcuts;
  },
  mounted: async function () {
    this.canModify = auth.canModify();
    if (this.canModify) {
      this.filtersSection.additionalFilters.push("employeeTypes");
    }
    var vm = this;
    vm.sites = this.$configStore.data.sites || [];
    vm.units = this.$configStore.data.units || [];
    vm.rooms = this.$configStore.data.rooms || [];
    console.clear();
    vm.symptomTypes = this.$configStore.data.symptomTypes || [];
    vm.siteAndTypeOptions = this.$configStore.data.siteAndTypeOptions || [];
    vm.symptoms = this.$configStore.data.symptoms || [];
    vm.organisms = this.$configStore.data.organisms || [];
    vm.precautions = this.$configStore.data.precautions || [];
    vm.treatments = this.$configStore.data.treatments || [];
    vm.origins = this.$configStore.data.origins || [];
    const config = await this.$http.get(`${window.CONFIG.treatement_api}/config`).then((resp) => resp.json());
    this.medicationsLookup = util.arrayToObj(config.medications, "id");

    this.clientList = [];
    this.client = this.$configStore.client || {};

    vm.$watch("graphPeriodFilter", function () {
      if (vm.graphPeriodFilter === "By Year") {
        vm.filterPeriods = "year";
      } else if (vm.graphPeriodFilter === "By Quarter") {
        vm.filterPeriods = "quarter";
      } else if (vm.graphPeriodFilter === "By Month") {
        vm.filterPeriods = "month";
      } else if (vm.graphPeriodFilter === "By Week") {
        vm.filterPeriods = "week";
      }
    });

    vm.$watch("symptomDetailPageSize", function () {
      vm.symptomDetailPage = 1;
      vm.loadCases();
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-dialog .el-row {
  line-height: 3em;
}

.filter-group-label {
  padding: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
}

.new-case-dialog div {
  line-height: 3em;
}

.delete-dialog div {
  line-height: 3em;
}

.delete-dialog .el-dialog div.el-dialog__body {
  padding-top: 0;
}

.graph {
  overflow-x: scroll;
  position: relative;
  width: 100%;
  height: 450px;
  padding: 1em;
}

.graph > canvas {
  position: absolute;
  left: 0;
  top: 0;
  width: 1500px;
  pointer-events: none;
}

.el-card {
  margin-bottom: 1em;
}

div.profile-photo {
  width: 10em;
  height: 10em;
  border-radius: 50%;
  position: absolute;
  right: 2.5em;
  top: 0.5em;
  display: block;
  overflow: hidden;
  border-radius: 50%;
}

.profile-photo img {
  width: auto;
  height: 100%;
}

div.profile-row-photo {
  width: 4em;
  height: 4em;
  overflow: hidden;
  border-radius: 2em;
  border: solid 1px lightgray;
}

.profile-row-photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dialog-case-detail div {
  line-height: 3em;
}

.input-boxes {
  border-style: solid;
  border-width: 1px;
  border-color: lightgray;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix {
  clear: both;
}

/*.el-button {
  width: 100%;
}*/

.el-tag {
  margin-right: 0.5em;
}

.color-code-label-0 {
  width: 1em;
  height: 1em;
  background: #00d560;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-1 {
  width: 1em;
  height: 1em;
  background: #0080ff;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-2 {
  width: 1em;
  height: 1em;
  background: #ffde25;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.color-code-label-3 {
  width: 1em;
  height: 1em;
  background: gray;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-width: 1px;
  border-color: lightgrey;
}
.graph-filter > .el-radio-group {
  padding: 15px;
  float: right;
}
.graph-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: -0.5em;
}
.case-filter > .el-pagination {
  padding-top: 1px;
  float: right;
}
.case-filter > .case-page-size-filte {
  width: 100px;
}
.case-filter > .case-export-button {
  float: left;
  margin-right: 1em;
  margin-top: 1px;
}
.case-filter {
  margin-right: 1em;
  margin-left: 3em;
  position: absolute;
  right: 0;
  top: 0.5em;
}
.filter-title-label {
  margin-left: 2em;
  margin-right: 1em;
  text-align: right;
  line-height: 40px;
  width: 100px;
  font-size: 0.8em;
}
.filter-section > label {
  display: inline-block;
  width: 355px;
}

.filter-section .el-input {
  float: right;
  position: relative;
  width: 195px;
}

.filter-section {
  margin: 1em;
  padding-bottom: 0.7em;
  display: table-cell;
  vertical-align: middle;
  height: 50px;
  padding-top: 0.5em;
}

.filter-section label {
  text-align: right;
}

.filter-section-input {
  float: right;
}
.symptom-status-image {
  width: 36px;
  position: relative;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.symptom-detail-status-image {
  width: 36px;
  position: absolute;
  margin: auto;
  margin-left: 5px;
  top: 5px;
}
.case-note {
  max-height: 15em;
  width: 100%;
  overflow-y: scroll;
}

.dialog-case-detail div.case-note-date {
  float: right;
  padding: 1em;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.8em;
}

.dialog-case-detail div.case-note-content {
  padding: 1em;
  border-bottom: 1px solid #eeeeee;
  line-height: 1.8em;
}

.case-note::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

.case-note::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.surveillance .el-tabs__item {
  font-size: 1em;
  padding: 0 10px 0 10px;
}

.surveillance .el-dialog.el-dialog--large {
  width: 80% !important;
}

.el-popover .el-row {
  line-height: 2.5em;
  font-family: sans-serif;
  font-size: 1.2em;
}

.el-popover .el-row .el-switch {
  float: right;
}

.dialog-footer {
  float: right;
  margin: 1em;
  margin-top: 2em;
}
.rx::after {
  content: ";";
}
.rx:last-child::after {
  content: "";
}
.filter-append {
  margin-top: 4px;
  float: left;
}
</style>
