<template>
  <div class="sync-button-container" v-if="pccEnabled">
    <el-tooltip content="Treatment sync is only available twice per day for each resident" :disabled="syncEnabled">
      <el-button class="sync-button" type="warning" @click="syncTreatments" :disabled="!syncEnabled"> Sync Treatments </el-button>
    </el-tooltip>
    <span v-if="lastSync" class="last-sync">Last sync: {{ lastSync }}</span>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SyncTreatments",
  props: ["clientId"],
  data() {
    return {
      lastSync: "",
      syncEnabled: false,
      pccEnabled: false,
    };
  },
  methods: {
    async fetchSyncStatus() {
      try {
        const response = await this.$http.get(`${window.CONFIG.pcc_integration_api}/medications/client/${this.clientId}/sync-status`);
        const data = await response.json();
        const { lastSync, syncEnabled } = data;
        this.lastSync = lastSync ? moment(lastSync).format(`${this.$configStore.dateFormat()} HH:mm`) : "Never";
        this.syncEnabled = syncEnabled;
      } catch (error) {
        console.error("Error fetching last synced date:", error);
      }
    },
    async syncTreatments() {
      if (!this.syncEnabled) return;
      this.syncEnabled = false;
      this.lastSync = "Syncing...";
      try {
        await this.$http.post(`${window.CONFIG.pcc_integration_api}/medications/client/${this.clientId}`);
        this.lastSync = "Now";
        this.$emit("treatment-sync-complete");
      } catch (error) {
        if (error?.status === 404) {
          this.lastSync = "Patient not found";
        } else {
          this.lastSync = "Error";
        }
        console.error("Error during sync:", error);
      }
    },
  },
  created() {
    this.pccEnabled = this.$configStore.pccIntegrationEnabled();
    if (this.pccEnabled) {
      this.fetchSyncStatus();
    }
  },
};
</script>
<style scoped>
.sync-button-container {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-right: 5px;
}

.last-sync {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px !important;
  white-space: nowrap;
}
</style>
