<template>
  <el-form v-loading="dialogBusy" ref="syncForm" :model="formModel" :rules="formRules" label-width="120px">
    <!-- Facility Selector -->
    <el-form-item label="Facility" prop="facId">
      <el-select v-model="formModel.facId" placeholder="Select a facility" @change="handleFacilityChange">
        <el-option v-for="facility in facilities" :key="facility.id" :label="facility.displayText" :value="facility.id"></el-option>
      </el-select>
      <span v-if="lastSync" class="last-sync">Last sync: {{ lastSync }}</span>
    </el-form-item>

    <!-- Sync Button -->
    <div class="dialog-footer">
      <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
      <el-tooltip content="Immunization sync is only available once per day for each facility" :disabled="syncEnabled">
        <el-button type="primary" @click="syncImmunizationsForFacility" :disabled="dialogBusy || !syncEnabled">Sync</el-button>
      </el-tooltip>
    </div>
  </el-form>
</template>


<script>
import moment from "moment";

export default {
  name: "SyncImmunizationsForFacility",
  data() {
    return {
      facilities: [],
      dialogBusy: true,
      lastSync: "",
      syncEnabled: false,
      formModel: {
        facId: null,
      },
      formRules: {
        facId: [{ required: true, type: "number", message: "Please select a facility", trigger: "change" }],
      },
    };
  },
  methods: {
    async fetchSyncStatus() {
      if (!this.formModel.facId) return;
      this.dialogBusy = true;
      try {
        const response = await this.$http.get(`${window.CONFIG.pcc_integration_api}/immunizations/facility/${this.formModel.facId}/sync-status`);
        const data = await response.json();
        this.lastSync = data.lastSync ? moment(data.lastSync).format(`${this.$configStore.dateFormat()} HH:mm`) : "Never";
        this.syncEnabled = data.syncEnabled;
        this.dialogBusy = false;
      } catch (error) {
        console.error("Error fetching sync status:", error);
        this.lastSync = "Error fetching sync status";
        this.syncEnabled = false;
        this.dialogBusy = false;
      }
    },
    async syncImmunizationsForFacility() {
      if (!this.syncEnabled) return;
      this.dialogBusy = true;
      this.syncEnabled = false;
      this.lastSync = "Syncing...";
      try {
        await this.$http.post(`${window.CONFIG.pcc_integration_api}/immunizations/facility/${this.formModel.facId}`);
        this.lastSync = "Now";
        this.$emit("immunization-sync-complete");
      } catch (error) {
        this.lastSync = "Error";
        this.dialogBusy = false;
        console.error("Error during sync:", error);
      }
    },
    cancelForm() {
      this.$refs.syncForm.resetFields();
      this.$emit("immunization-sync-close");
    },

    handleFacilityChange() {
      this.fetchSyncStatus();
    },
  },
  created() {
    this.facilities = this.$configStore.data.sites;
    this.dialogBusy = false;
  },
};
</script>

<style scoped>
.last-sync {
  display: block;
  font-size: 12px;
  color: gray;
}
</style>

