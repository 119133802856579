<template>
  <el-form v-loading="dialogBusy" ref="editorForm" :model="formModel" :rules="formRules" label-width="120px">
    <!-- First Name -->
    <el-form-item label="First Name" prop="firstName">
      <el-input :disabled="disableInput" :maxlength="250" placeholder="First Name" v-model="formModel.firstName"></el-input>
    </el-form-item>
    <!-- Middle Name -->
    <el-form-item label="Middle Name" prop="middleName">
      <el-input :disabled="disableInput" :maxlength="250" placeholder="Middle Name" v-model="formModel.middleName"></el-input>
    </el-form-item>
    <!-- Last Name -->
    <el-form-item label="Last Name" prop="lastName">
      <el-input :disabled="disableInput" :maxlength="250" placeholder="Last Name" v-model="formModel.lastName"></el-input>
    </el-form-item>
    <!-- Staff ID -->
    <div v-if="isStaffPage">
      <el-form-item
        label="Staff ID"
        prop="staffId"
        :rules="[
          { required: true, message: 'This field is required' },
          { validator: validateStaffId, trigger: 'blur' },
        ]"
      >
        <el-input :maxlength="250" placeholder="Staff ID" :disabled="staffIdAutoGenEnabled || disableInput" v-model="formModel.staffId"></el-input>
      </el-form-item>
    </div>
    <!-- Patient ID -->
    <div v-else>
      <el-form-item
        v-if="!adtEnabled"
        label="Patient ID"
        prop="patientId"
        :rules="[
          { required: true, message: 'This field is required' },
          { type: 'number', message: 'Patient ID must be a number' },
          { validator: validatePatientId, trigger: 'blur' },
        ]"
      >
        <el-input :disabled="pccIntegrationEnabled" :maxlength="250" placeholder="Patient ID" v-model.number="formModel.patientId"></el-input>
      </el-form-item>
      <el-form-item label="Patient ID" v-else prop="patientId">
        <el-input :disabled="adtEnabled || pccIntegrationEnabled" v-model="formModel[$configStore.patientIdType()]"></el-input>
      </el-form-item>
    </div>
    <!-- Facilities - staff + multi-facility only -->
    <el-form-item v-if="isStaffPage && isMultiFacility" label="Facility" prop="facilities">
      <el-select :disabled="facilities.length === 1" v-model="formModel.facilities" style="width: 100%" value-key="id" filterable multiple v-on:change="onFacilitiesChange">
        <el-option v-for="item in facilities" :key="item.id" :label="item.displayText" :value="item.id"> </el-option>
      </el-select>
    </el-form-item>
    <!-- Facility -->
    <el-form-item v-else label="Facility" prop="facility">
      <el-select :disabled="facilities.length === 1 || (pccIntegrationEnabled && !isStaffPage)" v-model="formModel.facility" style="width: 100%" value-key="id" filterable v-on:change="onFacilityChange">
        <el-option v-for="item in facilities" :key="item.id" :label="item.displayText" :value="item"> </el-option>
      </el-select>
    </el-form-item>
    <!-- Unit - staff only -->
    <el-form-item v-if="isStaffPage" label="Unit" prop="unit">
      <el-select :disabled="!formModel.facilities.length && !formModel.facility" value-key="id" v-model="formModel.unit" style="width: 100%" filterable>
        <el-option v-for="item in units" :key="item.id" :label="item.displayText" :value="item"></el-option>
      </el-select>
    </el-form-item>
    <!-- Employee type - staff only -->
    <el-form-item v-if="isStaffPage" label="Position" prop="employeeType">
      <el-select v-model="formModel.employeeType" filterable>
        <el-option v-for="item in employeeTypes" :key="item" :label="item" :value="item"></el-option>
      </el-select>
    </el-form-item>
    <!-- Provider - staff only -->
    <el-form-item v-if="isStaffPage" label="Provider" prop="providerType">
      <el-select v-model="formModel.providerType">
        <el-option label="Yes" value="Yes"></el-option>
        <el-option label="No" value="No"></el-option>
      </el-select>
      <Icon iconKey="info" description="Provider is an individual who prescribes medications to residents/patients" />
    </el-form-item>
    <!-- Gender (residents position) -->
    <el-form-item v-if="!isStaffPage" label="Gender" prop="gender">
      <el-select :disabled="disableInput" v-model="formModel.gender">
        <el-option v-for="g in genders" :key="g.label" :label="g.label" :value="g.value"> </el-option>
      </el-select>
    </el-form-item>
    <!-- Date of Birth (residents position) -->
    <el-form-item v-if="!isStaffPage" label="DOB" prop="dateOfBirth">
      <el-date-picker :disabled="disableInput" v-model="formModel.dateOfBirth" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Pick a date"></el-date-picker>
    </el-form-item>
    <el-collapse accordion class="custom-collapse">
      <el-collapse-item title="Additional Fields">
        <!-- Gender (staff position) -->
        <el-form-item v-if="isStaffPage" label="Gender" prop="gender">
          <el-select :disabled="disableInput" v-model="formModel.gender">
            <el-option v-for="g in genders" :key="g.label" :label="g.label" :value="g.value"> </el-option>
          </el-select>
        </el-form-item>
        <!-- Date of Birth (staff position) -->
        <el-form-item v-if="isStaffPage" label="DOB" prop="dateOfBirth">
          <el-date-picker :disabled="disableInput" v-model="formModel.dateOfBirth" type="date" :format="$configStore.datePickerDateFormat()" placeholder="Pick a date"></el-date-picker>
        </el-form-item>
        <!-- Address 1 -->
        <el-form-item label="Address 1" prop="address1">
          <el-input :disabled="disableInput" :maxlength="250" placeholder="Address 1" v-model="formModel.address1"></el-input>
        </el-form-item>
        <!-- Address 2 -->
        <el-form-item label="Address 2" prop="address2">
          <el-input :disabled="disableInput" :maxlength="250" placeholder="Address 2" v-model="formModel.address2"></el-input>
        </el-form-item>
        <!-- City -->
        <el-form-item label="City" prop="city">
          <el-input :disabled="disableInput" :maxlength="250" placeholder="City" v-model="formModel.city"></el-input>
        </el-form-item>
        <!-- Province/State -->
        <el-form-item label="Province/State" prop="state">
          <el-input :disabled="disableInput" :maxlength="250" placeholder="Province/State" v-model="formModel.state"></el-input>
        </el-form-item>
      </el-collapse-item>
      <div class="dialog-footer">
        <el-row>
          <el-button @click="cancelForm" :disabled="dialogBusy">Cancel</el-button>
          <el-button type="primary" @click="commitForm(false)" :disabled="dialogBusy || disableInput || !canModify">{{ submitButton }}</el-button>
          <el-button v-if="!isStaffPage && !isEdit" type="primary" @click="commitForm(true)" :disabled="dialogBusy">Create Resident and Assign Bed</el-button>
        </el-row>
      </div>
    </el-collapse>
  </el-form>
</template>
<script>
import Icon from "../Shared/Icon";
import auth from "../../auth";

const validateNotEmpty = (rule, value, callback) => {
  if (!value) {
    callback("This field cannot be empty");
  }
  callback();
};
const validateArrayNotEmpty = (rule, value, callback) => {
  if (!value.length) {
    callback("This field cannot be empty");
  }
  callback();
};
import moment from "moment";

export default {
  name: "client-editor",
  props: ["client", "isStaffPage"],
  components: {
    Icon,
  },
  computed: {
    disableInput() {
      /* all inputs are disabled if:
       *  - creating/editing a resident AND adt is enabled
       *  OR
       *  - creating/editing a staff AND user's org is ext
       */
      return (!this.isStaffPage && (this.adtEnabled || this.pccIntegrationEnabled)) || (this.isStaffPage && this.isExt);
    },
  },
  data() {
    return {
      isEdit: false,
      isMultiFacility: false,
      pccIntegrationEnabled: false,
      patientIdType: undefined,
      canModify: false,
      adtEnabled: false,
      isExt: false,
      staffIdAutoGenEnabled: false,
      staffIdConflict: false,
      patientIdConflict: false,
      submitButton: "",
      dialogVisibility: true,
      dialogBusy: true,
      facilities: [],
      units: [],
      employeeTypes: [],
      genders: [
        {
          label: "Male",
          value: "MALE",
        },
        {
          label: "Female",
          value: "FEMALE",
        },
        {
          label: "Other",
          value: "UNKNOWN",
        },
      ],
      formRules: {
        firstName: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        lastName: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        facility: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        facilities: [{ required: true, validator: validateArrayNotEmpty, message: "This field is required", trigger: "blur" }],
        providerType: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
        employeeType: [{ required: true, validator: validateNotEmpty, message: "This field is required", trigger: "blur" }],
      },
      formModel: {
        firstName: null,
        middleName: null,
        lastName: null,
        staffId: null,
        patientId: null,
        facility: null,
        unit: null,
        facilities: [],
        gender: null,
        dateOfBirth: null,
        address1: null,
        address2: null,
        employeeType: null,
        providerType: null,
        city: null,
        state: null,
      },
    };
  },
  watch: {
    "formModel.staffId"() {
      if (this.staffIdConflict) {
        this.staffIdConflict = false;
        this.$refs.editorForm.validateField("staffId");
      }
    },
    "formModel.patientId"() {
      if (this.patientIdConflict) {
        this.patientIdConflict = false;
        this.$refs.editorForm.validateField("patientId");
      }
    },
  },
  methods: {
    validateStaffId(rule, value, callback) {
      if (this.staffIdConflict) {
        callback(new Error("This Staff ID already exists"));
      } else {
        callback();
      }
    },
    validatePatientId(rule, value, callback) {
      if (this.patientIdConflict) {
        callback(new Error("This Patient ID already exists"));
      } else {
        callback();
      }
    },
    onFacilityChange(facility) {
      const facId = facility.id;
      if (facId) {
        // reset unit if it does not belong to selected fac
        if (this.formModel?.unit?.site?.id !== facId) {
          this.formModel.unit = null;
        }
        this.setGlobalUnits([facId]);
      } else {
        // reset unit if no fac is selected
        this.formModel.unit = null;
      }
    },
    onFacilitiesChange(facIds) {
      if (facIds.length) {
        this.setGlobalUnits(facIds);
      } else {
        // reset unit if no facilities are selected
        this.formModel.unit = null;
      }
    },
    setGlobalUnits(facIds) {
      this.units = this.facilities
        .filter((fac) => facIds.includes(fac.id))
        .reduce((units, fac) => {
          const validUnits = fac.units.filter((unit) => unit.code !== "PLACE_HOLDER");
          return units.concat(validUnits);
        }, []);
    },
    async setupForm() {
      this.setupCommonFields();
      if (this.isStaffPage) {
        await this.setupStaffFields();
      } else {
        await this.setupResidentFields();
      }
    },
    setupCommonFields() {
      if (this.isEdit) {
        this.submitButton = "Update";
        this.formModel.firstName = this.client.firstName;
        this.formModel.middleName = this.client.middleName || null;
        this.formModel.lastName = this.client.lastName;
        this.formModel.gender = this.client.gender;
        this.formModel.dateOfBirth = this.client.dateOfBirth ? moment(this.client.dateOfBirth).format(this.$configStore.dateFormat()) : null;
        this.formModel.address1 = this.client.address1 || null;
        this.formModel.address2 = this.client.address2 || null;
        this.formModel.city = this.client.city || null;
        this.formModel.state = this.client.state || null;
      } else {
        this.submitButton = "Create " + (this.isStaffPage ? "Staff" : "Resident");
      }
    },
    setupResidentFields() {
      if (this.isEdit) {
        if (!this.adtEnabled || !this.patientIdType) {
          this.formModel.patientId = this.client.patientId;
        } else {
          this.formModel[this.patientIdType] = this.client[this.patientIdType];
        }
        this.formModel.staffId = this.client.staffId || null;
        this.formModel.facility = this.facilities.find((fac) => fac.id === this.client.facId);
      } else {
        if (!this.isMultiFacility) {
          const facility = this.facilities[0];
          this.formModel.facility = facility;
          this.onFacilityChange(facility);
        }
      }
    },
    async setupStaffFields() {
      if (this.isEdit) {
        this.formModel.staffId = this.client.staffId;
        this.formModel.employeeType = this.client.employeeType || null;
        this.formModel.providerType = this.client.providerType || null;
        const facIds = this.client.facilities.length ? this.client.facilities : [this.client.facId];
        if (!this.isMultiFacility) {
          const facility = this.facilities.find((fac) => fac.id === facIds[0]);
          this.formModel.facility = facility;
          if (facility) {
            this.formModel.unit = facility.units.find((unit) => unit.id === this.client.unitId) || null;
          }
          this.onFacilityChange(facility);
        } else {
          this.formModel.facilities = facIds;
          const facilities = this.facilities.filter((fac) => facIds.includes(fac.id));
          this.formModel.unit = this.findUnitInFacilities(facilities, this.client.unitId);
          this.onFacilitiesChange(facIds);
        }
      } else {
        if (!this.isMultiFacility) {
          const facility = this.facilities[0];
          this.formModel.facility = facility;
          this.onFacilityChange(facility);
        }

        this.formModel.providerType = "No";

        if (this.staffIdAutoGenEnabled) {
          const response = await this.$http.get(`${window.CONFIG.api}/clients/laststaffid`);
          const prefix = this.$configStore.staffIdPrefix() ? this.$configStore.staffIdPrefix() : "";
          this.formModel.staffId = prefix + response.bodyText;
        }
      }
    },
    cancelForm() {
      this.$refs.editorForm.resetFields();
      this.$emit("editor-close");
    },
    getUnitDisplay(unit) {
      if (this.formModel.facilities.length === 1 || !this.isMultiFacility) {
        return unit.displayText;
      }
      // append facility name to unit when >1 facility is selected
      return `${unit.displayText} (${unit.site.displayText})`;
    },
    findUnitInFacilities(facilities, unitId) {
      if (!facilities.length || !unitId) {
        return null;
      }
      for (let facility of facilities) {
        let unit = facility.units.find((unit) => unit.id === unitId);
        if (unit) {
          return unit;
        }
      }
      return null;
    },
    async commitForm(assignResident) {
      this.$refs.editorForm.validate(async (valid) => {
        if (valid) {
          try {
            this.dialogBusy = true;
            const raw = { ...this.formModel };
            const clientType = this.isStaffPage ? "Staff" : "Client";

            const client = {
              clientType,
              firstName: raw.firstName,
              middleName: raw.middleName,
              lastName: raw.lastName,
              staffId: raw.staffId,
              patientId: raw.patientId || 0,
              unitId: raw.unit?.id || 0,
              facId: raw.facilities.length ? raw.facilities[0] : raw.facility.id,
              facilities: raw.facilities.length ? raw.facilities : [raw.facility.id],
              bedId: null,
              gender: raw.gender,
              dateOfBirth: raw.dateOfBirth ? moment(raw.dateOfBirth).toISOString() : null,
              address1: raw.address1,
              address2: raw.address2,
              city: raw.city,
              state: raw.state,
              postalCode: null,
              providerType: raw.providerType,
              employeeType: raw.employeeType,
            };

            if (!this.isStaffPage && this.patientIdType) {
              client[this.patientIdType] = raw[this.patientIdType];
            }
            let result;
            if (this.isEdit) {
              result = await this.$http.put(`${window.CONFIG.client_api}/${this.client.id}`, client);
            } else {
              // when creating a new staff, assign default staff photo
              if (this.isStaffPage) {
                client.photo = "clients/clientphoto/general/staff_placeholder.png";
              }
              result = await this.$http.post(`${window.CONFIG.client_api}`, client);
            }
            this.dialogBusy = false;
            if (assignResident) {
              client.id = result.body.id;
              this.$emit("editor-assign", client);
            } else {
              this.$emit("editor-save");
            }
          } catch (error) {
            // 409 indicates ID is not unique
            if (error?.status === 409) {
              if (this.isStaffPage) {
                this.staffIdConflict = true;
              } else {
                this.patientIdConflict = true;
              }
              // trigger validation to show ID conflict message
              this.$refs.editorForm.validateField(this.isStaffPage ? "staffId" : "patientId");
              this.dialogBusy = false;
            } else {
              this.$emit("editor-error");
            }
          }
        } else {
          return false;
        }
      });
    },
  },
  async created() {
    this.facilities = this.$configStore.data.sites;
    this.isMultiFacility = this.facilities.length > 1;
    this.pccIntegrationEnabled = this.$configStore.pccIntegrationEnabled();
    this.isEdit = !!this.client?.id;
    this.canModify = auth.canModify();
    this.staffIdAutoGenEnabled = this.$configStore.staffIdAutoGen();
    this.patientIdType = this.$configStore.patientIdType();
    this.adtEnabled = this.$configStore.adtEnabled();
    this.isExt = auth.userInfo().org === "ext" || auth.userInfo().org === "extdemo";
    this.employeeTypes = this.$configStore.employeeTypes() || [];
    await this.setupForm();
    this.dialogBusy = false;
  },
};
</script>
<style>
.custom-collapse {
  border: none;
}
.custom-collapse .el-collapse-item__header {
  padding: 0;
  border: none;
  font-weight: bold;
  margin-left: 100px;
}
.custom-collapse .el-collapse-item__wrap {
  border: none;
  background-color: transparent;
}
.custom-collapse .el-collapse-item__content {
  padding: 0;
}
</style>
